// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var wrapper = "style-module--wrapper--1UCvr";
export var bgWrapper = "style-module--bgWrapper--11VIP";
export var fadein = "style-module--fadein--2ek9v";
export var contentColumn = "style-module--contentColumn--2nGWs";
export var aboutColumnSlidein = "style-module--about-column-slidein--1xqa2";
export var columnDecoration = "style-module--columnDecoration--2RxN9";
export var aboutColumnDecorationSlidein = "style-module--about-column-decoration-slidein--1wVsb";
export var contentWrapper = "style-module--contentWrapper--3_sK4";
export var aboutColumnContentSlidein = "style-module--about-column-content-slidein--2XEN7";
export var buttonClose = "style-module--buttonClose--1cgFZ";
export var title = "style-module--title--4xGtF";
export var text = "style-module--text--3W0O7";
export var link = "style-module--link--22vg7";
export var links = "style-module--links--3ajeg";
export var emoji = "style-module--emoji--HhMR7";