// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var lightestFill = "#f5f5f5";
export var wrapper = "style-module--wrapper--1qVdW";
export var fadein = "style-module--fadein--1Zdk3";
export var timelineWrapper = "style-module--timelineWrapper--1jZZ-";
export var nextControl = "style-module--nextControl--2-KKX";
export var previousControl = "style-module--previousControl--6WJwq";
export var arrowBox = "style-module--arrowBox--8963B";