import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import fonts from "../../theme/fonts.module.css"
import * as styles from "./style.module.css"

export default ({ title, children }) => (
  <div className={styles.wrapper}>
    <Helmet
      title={title || "RockingElevator"}
      meta={[
        { name: "description", content: "Coder & Designer" },
        { name: "keywords", content: "design, ui, ux, apps, flutter" },
      ]}
    >
      <meta name="og:title" content="RockingElevator" />
      <meta name="og:description" content="Coder & Designer" />
      <meta
        name="og:image"
        content="https://rockingelevator.com/rockingelevator_cover.jpg"
      />

      <meta name="twitter:title" content="RockingElevator" />
      <meta
        name="twitter:description"
        content="Digital gypsy, gonzo app maker."
      />
      <meta
        name="twitter:image"
        content="https://rockingelevator.com/rockingelevator_twitter_cover.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="og:type" content="website" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link rel="icon" type="image/png" href="/favicon.png?3" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#333333" />
      <meta name="msapplication-TileColor" content="#e62047" />
      <meta name="theme-color" content="#e62047" />
      <link rel="stylesheet" href="/css/global.css" />
      <link rel="stylesheet" href="/fonts/iconfont/style.css" />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
    {children}
  </div>
)
