import React, { Component } from "react"
import {
  ChatMessage,
  MessageLoader,
  ChatOption,
  Timeline,
} from "../../components"
import * as styles from "./style.module.css"

const OPTIONS = ["works", "moreWorks", "skills", "contacts"]

export default class Chat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      chatFlow:
        typeof window !== "undefined" && window.location.hash
          ? [this._buildSelectedOption("works"), this._buildWorksSection()]
          : [],
      selectedOptions:
        typeof window !== "undefined" && window.location.hash ? ["works"] : [],
      // typing: true
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" })
  }

  //   componentDidMount() {
  //     this.scrollToBottom();
  //   }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  // componentDidMount() {
  //     setTimeout(
  //         function() {
  //             this.setState({typing: false})
  //         }.bind(this),
  //         1400
  //     )
  // }

  // 1. Intro

  _buildIntroSection = () => {
    return (
      <div className={styles.messages}>
        <ChatMessage delay="0s">
          Hi!{" "}
          <span role="img" aria-label="hi">
            👋
          </span>
        </ChatMessage>
        {/* { this.state.typing && <MessageLoader /> } */}
        <ChatMessage delay="1.5s">
          I'm Kirill, the interface designer and developer.
        </ChatMessage>
        <ChatMessage delay="1.8s" hasTail>
          I design and build apps since 2007.
        </ChatMessage>
      </div>
    )
  }

  // 2. Works
  _buildWorksSection = () => (
    <div>
      <div className={styles.messages}>
        <ChatMessage hasTail>I'm glad you ask</ChatMessage>
      </div>
      <Timeline />
    </div>
  )

  // 3. More works
  _buildMoreWorksSection = () => (
    <div className={styles.messages}>
      <ChatMessage>
        I spent 6 years at{" "}
        <a
          href="https://mediasapiens.de/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Mediasapiens.de
        </a>{" "}
        where we were working on a range of booking web/mobile apps and b2b
        services.
      </ChatMessage>
      <ChatMessage hasTail>
        After that I'd got a few years of product-making experience, working at{" "}
        <a href="https://djinni.co" target="_blank" rel="noreferrer noopener">
          Djinni
        </a>{" "}
        (#1 app for it-job search in Ukraine) and building my own apps.
      </ChatMessage>
    </div>
  )

  // 4. Skills
  _buildSkillsSection = () => (
    <div className={styles.messages}>
      <ChatMessage>Product prototyping</ChatMessage>
      <ChatMessage>design with code</ChatMessage>
      <ChatMessage>and</ChatMessage>
      <ChatMessage hasTail media>
        <img src="images/wine_skills.gif" />
      </ChatMessage>
      {/* <ChatMessage hasTail>
        <iframe src="//coub.com/embed/9sdaf?muted=false&autostart=false&originalSize=false&startWithHD=false" allowfullscreen frameborder="0" width="320" height="135" allow="autoplay"></iframe>
        </ChatMessage> */}
    </div>
  )

  // 5. Contacts
  _buildContactsSection = () => (
    <div className={styles.messages}>
      <ChatMessage>Sure</ChatMessage>
      <ChatMessage hasTail>
        <a href="mailto:rockingelevator@gmail.com">rockingelevator@gmail.com</a>
      </ChatMessage>
      <ChatMessage hasTail>
        <a href="https://t.me/rockingelevator">@rockingelevator</a>
      </ChatMessage>
    </div>
  )

  // OPTIONS

  _selectOption = option => {
    let flow = [...this.state.chatFlow]
    let opt = [...this.state.selectedOptions]
    opt.push(option)
    let section
    switch (option) {
      case "works":
        section = this._buildWorksSection()
        break
      case "moreWorks":
        section = this._buildMoreWorksSection()
        break
      case "skills":
        section = this._buildSkillsSection()
        break
      case "contacts":
        section = this._buildContactsSection()
        break
    }
    flow.push(this._buildSelectedOption(option))
    flow.push(section)
    this.setState({ chatFlow: flow, selectedOptions: opt })
  }

  _buildSelectedOption = option => (
    <div className={styles.options}>
      <ChatOption selected>{this._getOptionLabel(option)}</ChatOption>
    </div>
  )

  _buildOptionsSet = () =>
    this.state.selectedOptions.length < 1 ? (
      <div className={styles.options} style={{ animationDelay: "2s" }}>
        <ChatOption handleSelect={() => this._selectOption("works")}>
          Show me your works
        </ChatOption>
        <ChatOption handleSelect={this.props.openCV}>
          Who're you again?
        </ChatOption>
      </div>
    ) : (
      <div className={styles.options} style={{ animationDelay: "1s" }}>
        {OPTIONS.map(item => this._pushOption(item))}
      </div>
    )

  _getOptionLabel = option => {
    let label
    switch (option) {
      case "works":
        label = "Show me your works"
        break
      case "moreWorks":
        label = "Show me more works"
        break
      case "skills":
        label = "Skills?"
        break
      case "contacts":
        label = "Lets talk"
        break
    }
    return label
  }

  _pushOption = option => {
    if (this.state.selectedOptions.indexOf(option) > -1) return
    return (
      <ChatOption handleSelect={() => this._selectOption(option)}>
        {this._getOptionLabel(option)}
      </ChatOption>
    )
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.spacer} />
        {this._buildIntroSection()}
        {this.state.chatFlow}
        {this._buildOptionsSet()}
        {/* <div className={styles.messages}>
                    <ChatMessage>Hi! <span role='img' aria-label='hi'>👋</span></ChatMessage>
                    <ChatMessage hasTail>I'm Kirill. I design and build apps since 2007.</ChatMessage>
                    <MessageLoader />
                </div>
                <div className={styles.options}>
                    <ChatOption>Show me your works</ChatOption>
                    <ChatOption>Who're you again?</ChatOption>
                </div>
                <div className={styles.options}>
                    <ChatOption selected>Show me your works</ChatOption>
                </div>
                <div className={styles.messages}>
                    <ChatMessage hasTail>I'm glad you ask</ChatMessage>
                </div>
                <Timeline />
                <div className={styles.options}>
                    <ChatOption selected>Anything else?</ChatOption>
                </div>
                <div className={styles.messages}>
                    <ChatMessage hasTail><a href='https://mediasapiens.de/'>Mediasapiens.de</a></ChatMessage>
                </div>
                <div className={styles.options}>
                    <ChatOption selected>Skills?</ChatOption>
                </div>
                <div className={styles.messages}>
                    <ChatMessage hasTail>
                        <img src='https://media.giphy.com/media/62aDCkUJ8AogFbqcVV/giphy.gif' />
                    </ChatMessage>
                </div>
                <div className={styles.messages}>
                    <ChatMessage hasTail>
                    <iframe src="//coub.com/embed/9sdaf?muted=false&autostart=false&originalSize=false&startWithHD=false" allowfullscreen frameborder="0" width="320" height="135" allow="autoplay"></iframe>
                    </ChatMessage>
                </div> */}
        <a
          name="chat"
          ref={el => {
            this.messagesEnd = el
          }}
          className={styles.anchor}
        />
        {/* <div className={styles.spacer}/> */}
      </div>
    )
  }
}
