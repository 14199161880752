import React, { Component } from "react"
import * as styles from "./style.module.css"

import { TimelineItem, ArrowButton } from "../../components"

const ITEMS_COUNT = 7
const PADDING_LEFT = 40
const PADDING_RIGHT = 50
const MARGIN = 40

export default class Timeline extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0, leftOffset: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const isSmallViewport = this.state.width <= 640
    const itemWidth = isSmallViewport
      ? "auto"
      : (this.state.width - PADDING_LEFT - PADDING_RIGHT - MARGIN * 3) / 3
    const wrapperWidth =
      itemWidth * ITEMS_COUNT + PADDING_LEFT + PADDING_RIGHT + MARGIN * 7

    return (
      <div className={styles.wrapper}>
        <div
          className={styles.timelineWrapper}
          style={{
            width: isSmallViewport ? "auto" : wrapperWidth,
            // paddingLeft: isSmallViewport ? 'auto' : PADDING_LEFT,
            marginLeft: isSmallViewport ? "auto" : this.state.leftOffset,
          }}
        >
          <TimelineItem
            date="2021"
            width={itemWidth}
            image="images/gonzo_app_icon.png"
            title="Gonzo"
            subtitle="Self-publishing for modern authors"
            href="https://gonzo.page"
          />
          <TimelineItem
            date="2020"
            width={itemWidth}
            image="images/djinni_app_icon.png"
            title="Djinni"
            subtitle="Anonymous job search"
            link="/djinni"
          />
          <TimelineItem
            date="2019"
            width={itemWidth}
            image="images/uncrk_icon.png"
            title="Uncrk"
            subtitle="Taste wine like a Pro"
            href="https://uncrk.com"
          />
          <TimelineItem
            date="2018-2016"
            width={itemWidth}
            image="images/wanderolla_app_icon.png"
            title="Wanderolla"
            subtitle="Personalized travel guides"
            link="/wanderolla"
          />
          <TimelineItem
            date="2015"
            width={itemWidth}
            image="images/wooico_app_icon.png"
            title="Wooico"
            subtitle="Event manager"
            link="/wooico"
          />
          <TimelineItem
            date="2014"
            width={itemWidth}
            image="images/candy_app_icon_2.png"
            title="Candy Store"
            subtitle="CRM and b2b tools"
            link="/b2b"
          />
          <TimelineItem
            date="2012"
            width={itemWidth}
            image="images/fluffymap_app_icon.png"
            title="Fluffymap"
            subtitle="Share frisbee with the new fluffy friends"
            link="/fluffymap"
          />
        </div>
        {!isSmallViewport && this.state.leftOffset < 0 && (
          <div className={styles.previousControl}>
            <div className={styles.arrowBox}>
              <ArrowButton
                handleClick={() =>
                  this.setState({
                    leftOffset:
                      this.state.leftOffset + this.state.width <= 0
                        ? this.state.leftOffset + this.state.width
                        : 0,
                  })
                }
                left
              />
            </div>
          </div>
        )}
        {!isSmallViewport && this.state.leftOffset > -1 && (
          <div className={styles.nextControl}>
            <div className={styles.arrowBox}>
              <ArrowButton
                handleClick={() =>
                  this.setState({
                    leftOffset: 0 - (wrapperWidth - this.state.width),
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
