import React from "react"
import { Link } from "gatsby"
import * as styles from "./style.module.css"

import { DefaultLayout } from "../../layouts"
import { Logo, BackButton } from "../../components"

export default ({ title, teaser, note, year, children }) => (
  <DefaultLayout title={title + " by RockingElevator"}>
    <div className={styles.content}>
      <BackButton />
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.teaser}>{teaser}</p>
      <p className={styles.note}>{note}</p>
      <p className={styles.note}>{year}</p>
      {children}
    </div>
    <Logo color="#3D5CFF" link="/#chat" style={styles.toggle} />
  </DefaultLayout>
)
