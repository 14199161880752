import React from "react"
import { Logo, IconLink } from "../../components"
import * as styles from "./style.module.css"

export default ({ handleClose }) => (
  <div className={styles.wrapper}>
    <div className={styles.bgWrapper} />
    <div className={styles.columnDecoration} />
    <div className={styles.contentColumn} />
    <div className={styles.contentWrapper}>
      {/* <Logo handleClick={handleClose} /> */}
      <a className={styles.buttonClose} onClick={handleClose}>
        <i className="icon-close" />
      </a>

      <h1 className={styles.title}>
        Kirill
        <br />
        <strong>Meshkov</strong>
      </h1>
      <p className={styles.text}>
        <strong>Now</strong>
        <br />
        <span className={styles.emoji} role="img">
          🛵&nbsp;
        </span>{" "}
        Digital gypsy,&nbsp;
        <a
          className={styles.link}
          href="https://gonzo.page"
          target="_blank"
          rel="noreferrer noopener"
        >
          gonzo
        </a>
        &nbsp;app maker
      </p>
      <p className={styles.text}>
        <strong>2020</strong>
        <br />
        <span className={styles.emoji} role="img">
          🥃&nbsp;
        </span>{" "}
        Product Designer & Front Lead at&nbsp;
        <a
          className={styles.link}
          href="https://djinni.co"
          target="_blank"
          rel="noreferrer noopener"
        >
          Djinni
        </a>
      </p>
      <p className={styles.text}>
        <strong>2019</strong>
        <br />
        <span className={styles.emoji} role="img">
          🥳&nbsp;
        </span>{" "}
        Founder of&nbsp;
        <a
          className={styles.link}
          href="https://uncrk.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Uncrk
        </a>
      </p>
      <p className={styles.text}>
        <strong>2012 — 2018</strong>
        <br />
        <span className={styles.emoji} role="img">
          🤡&nbsp;
        </span>{" "}
        Chief Creative Officer at&nbsp;
        <a
          className={styles.link}
          href="https://mediasapiens.de/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Mediasapiens
        </a>
      </p>
      <p className={styles.text}>
        <strong>2008 — 2011</strong>
        <br />
        <span className={styles.emoji} role="img">
          🤖&nbsp;
        </span>{" "}
        Lead UI/UX Designer at&nbsp;
        <a
          className={styles.link}
          href="http://meta.ua/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Meta Ukraine
        </a>
      </p>
      <div className={styles.links}>
        <IconLink icon="icon-email" link="mailto:rockingelevator@gmail.com" />
        <IconLink icon="icon-paper-plane" link="https://t.me/rockingelevator" />
        <IconLink icon="icon-twitter" link="https://twitter.com/moonk" />
        <IconLink
          icon="icon-instagram-1"
          link="https://www.instagram.com/rockingelevator"
        />
      </div>
    </div>
  </div>
)
