// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var lightText = "#999999";
export var lightFill = "#e6e6e6";
export var wrapper = "style-module--wrapper--1uKPt";
export var fadein = "style-module--fadein--1yxI3";
export var option = "style-module--option--nGYya";
export var selectedOption = "style-module--selectedOption--3toCC";