// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var wrapper = "style-module--wrapper--21Xjp";
export var spacer = "style-module--spacer--1sNVk";
export var anchor = "style-module--anchor--bQ60b";
export var options = "style-module--options--1sXag";
export var messages = "style-module--messages--qmvWZ";
export var fadein = "style-module--fadein--3KUnP";