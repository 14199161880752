import React from "react"
import { Link } from "gatsby"
import * as styles from "./style.module.css"

export default ({ width, date, image, title, subtitle, style, link, href }) => {
  const LinkWrapper = ({ children }) =>
    href ? (
      <a href={href} target="_blank">
        {children}
      </a>
    ) : (
      <Link to={link}>{children}</Link>
    )
  return (
    <div className={styles.wrapper} style={{ width, ...style }}>
      <span className={styles.dot} />
      <p className={styles.date}>{date}</p>

      <div className={styles.content}>
        <LinkWrapper>
          <img className={styles.appIcon} src={image} />
        </LinkWrapper>
        <LinkWrapper>
          <span className={styles.title}>{title}</span>
        </LinkWrapper>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </div>
  )
}
