// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var medium = "800px";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var lightestFill = "#f5f5f5";
export var lightText = "#999999";
export var content = "style-module--content--1OJlY";
export var title = "style-module--title--1IgMS";
export var teaser = "style-module--teaser--3kiF9";
export var note = "style-module--note--1W5p6";
export var toggle = "style-module--toggle--1Eq8T";