// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var wrapper = "style-module--wrapper--2dVck";
export var fadein = "style-module--fadein---CePO";
export var media = "style-module--media--1qIc5";
export var bubble = "style-module--bubble--19lvj";
export var bubbleWithTail = "style-module--bubbleWithTail--3_LpW";