import React from "react"

import * as styles from "./style.module.css"

export default ({ hasTail = false, media = false, delay = 0, children }) => (
  <div className={styles.wrapper} style={{ animationDelay: delay }}>
    <div
      className={`${hasTail ? styles.bubbleWithTail : styles.bubble} ${
        media ? styles.media : ""
      }`}
    >
      {children}
    </div>
  </div>
)
