import React from "react"

import * as styles from "./style.module.css"

export default ({ selected = false, handleSelect, children }) => (
  <div className={styles.wrapper}>
    {!selected && (
      <a className={styles.option} onClick={handleSelect}>
        {children}
      </a>
    )}
    {selected && <div className={styles.selectedOption}>{children}</div>}
  </div>
)
