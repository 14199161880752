// extracted by mini-css-extract-plugin
export var bp = "'../../theme/breakpoints.module.css'";
export var xmedium = "640px";
export var small = "480px";
export var xsmall = "320px";
export var palette = "'../../theme/palette.module.css'";
export var accent = "#3D5CFF";
export var blackText = "#333333";
export var wrapper = "style-module--wrapper--C8ztB";
export var date = "style-module--date--2PP5Z";
export var dot = "style-module--dot--27gvO";
export var content = "style-module--content--wzwKW";
export var title = "style-module--title--3evpg";
export var subtitle = "style-module--subtitle--2PrjZ";
export var appIcon = "style-module--appIcon--11f0Z";