import React from "react"
import { Link } from "gatsby"
// import LogoSVG from "../../../static/images/logo.svg"
import * as styles from "./style.module.css"

export default ({
  handleClick,
  link,
  color = "#333",
  style,
  showBubble = false,
}) => {
  //   const logo = (
  //     <svg
  //       className={styles.logo}
  //       viewBox="0 0 30 40"
  //       version="1.1"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <title>RockingElevator</title>
  //       <g id="re" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  //         <g
  //           id="start-copy-5"
  //           transform="translate(-50.000000, -39.000000)"
  //           fill={color || "#333333"}
  //           className={styles.logoSVG}
  //         >
  //           <g id="elevator" transform="translate(50.000000, 39.000000)">
  //             <path
  //               d="M24.4030402,1.73010381 L5.00872456,1.73010381 L1.93298446,29.4117647 L27.4787803,29.4117647 L24.4030402,1.73010381 Z M3.36450452,0.861327844 C3.41735988,0.385629611 3.84585499,0 4.32468389,0 L25.0870808,0 C25.5645179,0 25.994369,0.385307311 26.0472602,0.861327844 L29.4117647,31.1418685 L0,31.1418685 L3.36450452,0.861327844 Z"
  //               id="Rectangle"
  //               fill-rule="nonzero"
  //             ></path>
  //             <path
  //               d="M3.13933591,38.9902083 L0,31.1418685 L29.4117647,31.1418685 L26.2724288,38.9902083 C26.0958326,39.4316989 25.5645179,39.7923875 25.0870808,39.7923875 L4.32468389,39.7923875 C3.84585499,39.7923875 3.31654846,39.4332397 3.13933591,38.9902083 Z"
  //               id="Rectangle-Copy"
  //             ></path>
  //             <polygon
  //               id="Rectangle-2"
  //               points="8.47750865 5.19031142 21.4532872 5.19031142 21.4532872 6.92041522 8.47750865 6.92041522"
  //             ></polygon>
  //             <polygon
  //               id="Rectangle-3"
  //               points="19.3771626 5.19031142 21.4532872 5.19031142 23.183391 24.2214533"
  //             ></polygon>
  //             <polygon
  //               id="Rectangle-3-Copy"
  //               points="10.3806228 5.19031142 6.57439446 24.2214533 8.30449827 5.19031142"
  //             ></polygon>
  //             <polygon
  //               id="Line-Copy"
  //               fill-rule="nonzero"
  //               points="14.6984473 27.7195862 17.6503095 12.0640002 15.1333337 13.458616 17.7153748 5.59443448 13.8297286 5.67216843 12.6175314 17.2704605 15.4638774 15.6117135"
  //             ></polygon>
  //           </g>
  //         </g>
  //       </g>
  //     </svg>
  //   )

  //   const logo = <img src={"images/logo.svg"} className={styles.logo} />

  const logo = <span className={`icon-logo ${styles.logo}`} style={{ color }} />

  return handleClick ? (
    <a className={`${styles.wrapper} ${style || ""}`} onClick={handleClick}>
      {logo}
      {showBubble && <span className={styles.bubble} />}
    </a>
  ) : (
    <Link to={link || "/"} className={style}>
      <span className={styles.wrapper}>{logo}</span>
    </Link>
  )
}
